@import 'styles/variables';

@keyframes ripple {
  0% {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
  40% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    left: -30px;
    right: -30px;
    top: -30px;
    bottom: -30px;
    opacity: 0;
  }
}

@mixin selected-effect() {
  cursor: pointer;
  transition: width 0.5s ease, height 0.5s ease;

  &:hover {
    filter: brightness(0.8);
  }

  &-selected {
    position: relative;
    color: #0d3d5f;

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      border: 1px solid #0d3d5f77;
      border-radius: 100%;
    }

    &:before {
      animation: ripple 1s linear infinite;
    }

    &:after {
      animation: ripple 1s linear 0.5s infinite;
    }
  }
}

.UtilityMap {
  width: 100%;
  height: 100%;
  border: 1px solid $component-grey-border;

  &__marker {
    color: $icon-error;
    font-size: $map-icon-size;
    width: 1em;
    height: 1em;
    display: flex;
    transform: translate(-50%, -50%);

    &--alert {
      color: $lightgrey;
      cursor: pointer;
    }

    &--alert:hover {
      color: $lightyellow;
    }

    &--alert-selected {
      color: $yellow;
    }

    &--sensorNormal {
      color: $green;
      transform: translate(-50%, -100%);
      @include selected-effect();
    }

    &--sensorAbnormal {
      color: $yellow;
      @include selected-effect();
    }

    &--technician {
      color: $lightgrey-text;
      cursor: pointer;
    }

    &--technician:hover {
      color: $blue-secondary;
    }

    &--technician-selected {
      color: $blue-primary;
    }
  }
}
