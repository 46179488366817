@import 'styles/variables';

.insights {
    background-color: white;
    border-radius: 10px;
    color: $text-grey-1;
    ::-webkit-scrollbar {
      width: 12px;
      height: 8px;
    }
  
  /* Track */
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background-color: $main-header-bg-color;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: gray; 
    }
    .title {
      color: $blue-primary;
      font-size: 16px;
      font-weight: bold;
    }
    .content-div {
      border-bottom: 1px solid gray;
      border-top: 1px solid gray;
      overflow: auto;
      padding-top: 20px;
      height: 300px;
      color: $text-grey-1;
      background-color: white;
      span {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }