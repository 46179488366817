@import 'styles/variables';

.weather {
    background-color: white;
    border-radius: 10px;
    height: 350px;
    color: $text-grey-1;    
    .title {
      color: $blue-primary;
      font-size: 16px;
      font-weight: bold;
    }
  }