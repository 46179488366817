@import 'styles/variables';

.scenario {
  .title {
    color: $blue-primary;
    font-size: 16px;
    font-weight: bold;
  }
  background-color: white;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
  .left-accerator {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    justify-content: space-around;
    .ant-select-show-arrow {
      width: 100%;
    }
    .ant-select-selection-item {
      color: $yellow !important;
    }
  }
  .ant-table-cell {
    color: $text-grey-1;
    span {
      font-size: 15px !important;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }
  .ant-table-thead > tr > th::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    padding: 8px 8px;
  }
  .ant-table {
    font-size: 15px;
  }
  .ant-row {
    display: block;
  }
  .caret-up {
    .val {
      color: $text-grey-1;
    }
    color: $green;
  }
  .caret-down {
    .val {
      color: $text-grey-1;
      margin-left: 3px;
    }
    color: $red;
  }
  .scenario-item button {
    width: 100px;
    margin-right: -10px;
  }
  .semicircle-percent-value {
    color: $yellow !important;
    font-weight: bolder;
  }
  .right-accerator {
    align-items: center;
    justify-content: center;
    span {
      color: $yellow !important;
      font-size: 18px;
    }
  }
  .scenario-item {
    padding: 0 10px;
    p {
      margin-top: 12px;
      font-weight: bold;
      font-size: 14px;
    }
    input {
      width: 31%;
      margin-right: 5px;
    }
    button {
      float: right;
      margin-top: 10px;
    }
  }
}
