@import 'styles/variables';

.SideNavMenu {
  margin-top: map-get($spacers, 6);
  &__active {
    background: $main-sidebar-hover-color;
  }
  position: relative;
  height:calc(100vh - 128px);
  .SideNavItem_setting{
    bottom: 20px;
    position: absolute;
    right: 0;
    left: 0;
  }
  .SideNavItem_help{
    bottom: 80px;
    position: absolute;
    right: 0;
    left: 0;
  }
}
