@import 'styles/variables';

.curve {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 750px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  .title {
    color: $blue-primary;
    font-size: 16px;
    font-weight: bold;
  }
  .mgd-caption {
    margin: 30px auto;
  }
  .btn_print{
    float: right;
  }
  .left-curve {
    align-items: center;
    justify-content: center;
    span {
      color: $yellow !important;
      font-size: 18px;
    }
  }
  .right-curve {
    align-items: center;
    justify-content: center;
    span {
      color: $teal !important;
      font-size: 18px;
    }
  }
  .ant-checkbox-wrapper {
    margin-bottom: 15px;
  }
  //checkbox-customize
  .teal .ant-checkbox .ant-checkbox-inner {
    border-color: $teal !important;
  }
  .yellow .ant-checkbox-inner {
    border-color: $yellow !important;
  }
  .yellow .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $yellow !important;
  }
  .red .ant-checkbox-inner {
    border-color: $red !important;
  }
  .red .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $red !important;
  }
  .green .ant-checkbox-inner {
    border-color: $green !important;
  }
  .green .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $green !important;
  }
  .orange .ant-checkbox-inner {
    border-color: $magenta !important;
  }
  .orange .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $magenta !important;
  }
  .round .ant-checkbox-inner {
    border-radius: 10px !important;
    background-color: none !important;
    border-color: $text-grey-1;
    outline: none !important;
  }
  .round .ant-checkbox-checked {
    background-color: none !important;
    outline: none !important;
  }
  span.round-progressbar-caption {
    font-size: 12px !important;
    color: $text-grey-1 !important;
    width: 100px;
    text-align: center;
  }
  .recharts-wrapper {
    margin-top: 10px;
    position: absolute;
    margin-left: -60px;
  }    
}