@import 'styles/variables';

.SidebarLogo {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-size: 14px;
  img {
    display: block;
    width: 30px;
    height: 30px;
    margin: auto;
  }
  img:hover {
    opacity: 0.3;
    cursor: pointer;
    transition: all 0.4s;
  } 
}

