@import 'styles/variables';

.OverviewMap {
  height: 350px;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    margin-bottom: map-get($spacers, 2);
  }

  &__body {
    flex: 1;
  }

  &__search {
    width: 15em;
  }
  .search-input {
    width: 100%;
  }
  .title {
    color: $blue-primary;
    font-size: 16px;
    font-weight: bold;
  }
  .title-div {
    width: 100%;
  }
  .content-div {
    color: $text-grey-1;
    background-color: white;
  }
}

.MapTypeOptions {
  &__option {
    &:last-child {
      margin: 0;
    }
  }
}
